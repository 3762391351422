import type { ComputedRef, Ref } from 'vue'
import type { AssetFragment, ProductFragment, ProductVariantFragment } from '#graphql-operations'

export function useProductImages(
  product?: Ref<ProductFragment>,
  selectedVariant?: ComputedRef<ProductVariantFragment | undefined>,
) {
  const productFeaturedImage = computed(() => {
    if (product?.value && product.value.featuredAsset)
      return { ...product.value.featuredAsset, alt: product.value.name }

    return undefined
  })

  const productImages = computed(() => {
    if (product?.value && product.value.assets)
      return product.value.assets.map(asset => ({ ...asset, alt: product.value.name }))

    return []
  })

  const variantImages = computed(() => {
    const variants = product?.value?.variants ?? []
    const images = variants.map(variant => variant.featuredAsset).filter(Boolean) as AssetFragment[]
    return images.map((image, index) => ({ ...image, alt: variants[index].name ?? product?.value?.name ?? '' }))
  })

  const selectedVariantFeaturedImage = computed(() => {
    if (selectedVariant?.value && selectedVariant.value.featuredAsset)
      return [{ ...selectedVariant.value.featuredAsset, alt: ((product?.value ? product.value.name : '') + (selectedVariant.value.options ?? []).map(o => o.name).join(' ')).trim() }]

    return []
  })

  const images = computed(() => [
    ...(productFeaturedImage.value ? [productFeaturedImage.value] : []),
    ...productImages.value,
    ...variantImages.value,
    ...selectedVariantFeaturedImage.value,
  ].filter((image, index, self) => image?.id && self.findIndex(i => i.id === image.id) === index))

  return { images }
}
